<template>
  <validation-observer ref="simpleRules">
    <b-card-code
      :title="$route.name == 'detail-kendaraan' ? 'Detail Log Kendaraan' : 'Edit Log Kendaraan'"
      :class="$route.name == 'detail-kendaraan' ? 'detail-dispo' : '' "
    >

      <b-button
        v-show="$route.name === 'detail-kendaraan' && authorize"
        variant="outline-primary"
        class="bg-gradient-primary mt-2"
        style="position: absolute;right: 15px;top: -10px;"
        type="submit"
        @click.prevent="goEdit()"
      >
        <span class="align-middle">Edit Log Kendaraan</span>
      </b-button>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Instansi"
            rules="required"
          >
            <b-form-group
              label="Nomor Kendaraan"
              label-for="Nomor Kendaraan"
            >
              <b-form-input
                id="nomorKendaraan"
                v-model="police_number"
                placeholder="Input Nomor Kendaraan"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Tipe Kendaraan"
            rules="required"
          >
            <b-form-group
              label="Tipe Kendaraan"
              label-for="Tipe Kendaraan"
            >
              <b-form-select
                id="Tipe Kendaraan"
                v-model="vehicle_type"
                placeholder="Input Tipe Kendaraan"
                :options="optionKendaraan"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Brand Kendaraan"
            rules="required"
          >
            <b-form-group
              label="Brand Kendaraan"
              label-for="Brand Kendaraan"
            >
              <b-form-input
                id="Brand Kendaraan"
                v-model="brand"
                placeholder="Input Brand Kendaraan"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Warna Kendaraan"
            rules="required"
          >
            <b-form-group
              label="Warna Kendaraan"
              label-for="Warna Kendaraan"
            >
              <b-form-input
                id="Warna Kendaraan"
                v-model="color"
                placeholder="Input Warna Kendaraan"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Tanggal"
            rules="required"
          >
            <b-form-group
              label="Tanggal"
              label-for="Tanggal"
            >
              <b-form-datepicker
                id="date-datepicker"
                v-model="date"
                locale="id"
                class="mb-1"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Kategori"
            rules="required"
          >
            <b-form-group
              label="Kategori"
              label-for="Kategori"
            >
              <v-select
                v-model="kategori"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                :disabled="$route.name == 'detail-kendaraan'"
                placeholder="Pilih Kategori"
                :options="optionsKategori"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <h5 class="titlerow mt-1">
            File Log Kendaraan
          </h5>
          <b-form-group label-for="FileSurat">
            <b-row
              v-if="gantiFile == false && file !== null"
              class="match-height"
            >
              <b-col
                v-for="item in file"
                :key="item.access_url"
                md="12"
                class="fileSurat"
              >
                <div
                  class="open-file"
                  @click="openFile(item.access_url)"
                >
                  <feather-icon
                    icon="FileIcon"
                    size="56"
                  />
                  <h5 class="ml-1">
                    Open File
                    <span> {{ item.real_filename }}{{ item.ext }} </span>
                  </h5>
                </div>
              </b-col>

              <b-button
                v-show="$route.name === 'edit-kendaraan'"
                v-model="gantiFile"
                variant="outline-primary"
                class="bg-gradient-primary mt-1 ml-1"
                style="height: 29px"
                size="sm"
                @click.prevent="toggleFile"
              >
                <span class="align-middle">Ganti File</span>
              </b-button>
            </b-row>

            <b-row v-else>
              <b-col md="12">
                <div style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-wrap: wrap;">
                  <b-form-file
                    id="FileSurat"
                    ref="file"
                    v-model="file"
                    type="file"
                    placeholder="Input File Surat"
                    drop-placeholder="Drop file here..."
                    multiple="multiple"
                    @change="fileChange"
                  />

                  <b-button
                    v-show="file !== null"
                    v-model="gantiFile"
                    size="sm"
                    variant="outline-primary"
                    class="bg-gradient-primary mt-1"
                    @click.prevent="toggleFile"
                  >
                    <span class="align-middle">Open File</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

            <validation-provider
              #default="{ errors }"
              class="mt-1"
              name="Tag"
              rules="required"
            >
              <b-form-group
                label="Tag"
                label-for="tag"
              >
                <b-form-tags
                  v-model="tags"
                  input-id="tags-basic"
                  placeholder="Tambah Tag"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

            <label
              for="textarea-default"
              class="mt-1"
            >Deskripsi</label>
            <b-form-textarea
              id="textarea-default"
              v-model="deskripsi"
              placeholder="Textarea"
              rows="3"
            />

            <label
              for="textarea-default"
              class="mt-1"
            >Catatan</label>
            <b-form-textarea
              id="textarea-default"
              v-model="catatan"
              placeholder="Textarea"
              rows="3"
            />

          </b-form-group>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Jenis Log"
            rules="required"
          >
            <b-form-group
              label="Jenis Log"
              label-for="Jenis Log"
            >
              <v-select
                v-model="type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                :disabled="$route.name == 'detail-kendaraan'"
                placeholder="Pilih Jenis Log"
                :options="optionLog"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <b-row
            v-for="(row, index) in Pengunjung"
            :key="index"
            class="mt-1"
            style="margin-bottom: 10px;position:relative"
          >
            <h5 class="titlerow">
              Driver {{ index + 1 }}
            </h5>
            <span
              v-show="index !== 0"
              class="warning2"
              @click="deletePengunjung(index)"
            > Hapus Driver </span>
            <b-col md="6">
              <b-form-group
                label="Nama Driver"
                label-for="Nama Driver"
                class="mb-1"
              >
                <b-form-input
                  id="Nama Driver"
                  v-model="row.name"
                  placeholder="Input Nama Driver"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nomor Pengunjung"
                label-for="Nomor Pengunjung"
                class="mb-1"
              >
                <b-form-input
                  id="Nomor Pengunjung"
                  v-model="row.visitor_number"
                  placeholder="Input Nomor Pengunjung"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nomor Telfon"
                label-for="Nomor Telfon"
              >
                <b-form-input
                  id="Kendaraan"
                  v-model="row.phone"
                  placeholder="Input Nomor Telfon"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Jenis Kelamin"
                label-for="Jenis Kelamin"
              >
                <b-form-select
                  v-model="row.gender"
                  placeholder="Pilih Tipe Kendaraan"
                  :options="optionGender"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            v-show="$route.name !== 'detail-kendaraan'"
            variant="outline"
            class="bg-gradient mb-2 bt-add"
            type="submit"
            size="sm"
            @click.prevent="addPengunjung"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Tambah Driver</span>
          </b-button>
        </b-col>
      </b-row>

      <b-button
        v-show="$route.name !== 'detail-kendaraan'"
        variant="outline-primary"
        class="bg-gradient-primary "
        type="submit"
        @click.prevent="validationForm"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-50"
        />
        <span class="align-middle">Simpan</span>
      </b-button>

    </b-card-code>
  </validation-observer>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BRow,
  BCol,
  BFormFile,
  BFormTags,
  BFormCheckboxGroup,
  BFormTextarea,
  BFormCheckbox,
  BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import axios from '@axios'
// import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'
import store from '@/store/index'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    email,
    BFormFile,
    BRow,
    BCol,
    BCardCode,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    ToastificationContent,
    BDropdownItem,
    BButton,
    BFormTags,
    BFormCheckboxGroup,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormDatepicker,
  },
  data() {
    return {
      NoSurat: '',
      vehicle_type: '',
      police_number: '',
      brand: '',
      authorize: false,
      gantiFile: false,
      email: '',
      number: '',
      address: '',
      color: '',
      file: [],
      tags: [],
      date: null,
      kategori: [],
      type: [],
      selected: [],
      deskripsi: '',
      catatan: '',
      options: [{ item: '', name: '' }],
      Pengunjung: [
        {
          name: '',
          visitor_number: '',
          phone: '',
          gender: '',
          email: '',
        },
      ],
      optionLog: [
        { value: 'IN', text: 'Masuk' },
        { value: 'OUT', text: 'Keluar' },
      ],
      optionGender: [
        { value: '', text: 'Pilih Jenis Kelamin' },
        { value: 'M', text: 'Pria' },
        { value: 'F', text: 'Wanita' },
      ],
      optionKendaraan: [
        { value: '', text: 'Pilih Tipe Kendaraan' },
        { value: 'Roda 2', text: 'Roda 2' },
        { value: 'Roda 4', text: 'Roda 4' },
        { value: 'Truk', text: 'Truk' },
      ],
      optionsKategori: [
        { value: 1, text: 'Produk,' },
        { value: 2, text: 'Layanan' },
        { value: 3, text: 'Proses Produksi,' },
        { value: 4, text: 'Pengiriman,' },
        { value: 5, text: 'Paska Pengiriman,' },
        { value: 6, text: 'Harga' },
      ],
    }
  },
  created() {
    this.authorize = store.state.userData.data.is_admin === 1
    this.userRole = store.state.userData.data.role.name
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    async fileChange(e) {
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('files', file)
      })
      const { data } = await axios.post('/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        params: {
          feature: 'VehicleTraffic',
        },
      })
      this.file = data.data
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.addRequest()
        }
      })
    },

    async getDetail() {
      const param = this.$route.params.pathMatch
      const { data } = await axios.get(`siap/vehicle-traffic/detail/${param}`)
      this.kategori = data.category.name
      this.NoSurat = data.ref_num
      this.deskripsi = data.desc
      this.catatan = data.note
      this.date = data.date
      this.file = data.file
      this.tags = data.tag.map(e => e.name)
      this.police_number = data.police_number
      this.vehicle_type = data.vehicle_type
      this.brand = data.brand
      this.type = data.type
      this.color = data.color
      this.Pengunjung = data.driver
    },

    async addRequest() {
      const param = this.$route.params.pathMatch
      await axios
        .post(`siap/vehicle-traffic/update/${param}`, {
          police_number: this.police_number,
          vehicle_type: this.vehicle_type,
          brand: this.brand,
          brand_type: '',
          type: this.type,
          color: this.color,
          date: dayjs(this.date).format('YYYY-MM-DDTHH:mm:ss+07:00'),
          driver: this.Pengunjung,
          cat: this.kategori.text,
          note: this.catatan,
          desc: this.deskripsi,
          file: this.file,
          tags: this.tags,
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          // window.location.href = `detail-disposisi/${response.data.data.id}`
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error.response.data.error.errors.map(e => e.message),
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },

    goEdit() {
      const param = this.$route.params.pathMatch
      window.location.href = `/kendaraan/edit/${param}`
    },

    openFile(e) {
      window.open(e, '_blank')
    },

    toggleFile() {
      if (this.gantiFile === false) {
        this.gantiFile = true
      } else {
        this.gantiFile = false
      }
    },
    addPengunjung() {
      this.Pengunjung = [
        ...this.Pengunjung,
        {
          name: '',
          visitor_number: '',
          phone: '',
          gender: '',
          email: '',
        },
      ]
    },

    deletePengunjung(index) {
      const current = this.Pengunjung.slice()
      current.splice(index, 1)
      this.Pengunjung = current
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
.row {
  margin-bottom: 20px;
}
.titlerow {
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  margin-left: 15px;
}

.form-group {
  margin-bottom: 5px;
}
.nopad {
  padding: 0;
}
.labelfull {
  .custom-checkbox {
    width: 100%;
  }
}
.labelfull .custom-checkbox {
  width: 100%;
}

.bt-add {
  background: #fdf6eb;
}

.warning2 {
  font-size: 11px;
  color: orange;
  position: absolute;
  top: -1px;
  right: 15px;
  cursor: pointer;
}

.warning-right {
  font-size: 11px;
  color: orange;
  position: absolute;
  top: 0;
  right: 15px;
}

.activity {
  .card-body {
    max-height: 400px;
    overflow-y: scroll;
  }
}

.fileSurat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: #a6a6a6;
    display: block;
    margin-top: 5px;
    font-size: 12px;
  }
}

[dir] .vs--disabled .vs__selected {
  background-color: #00427a;
  color: #fff;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #615f6f;
}

.open-file {
  display: flex;
  cursor: pointer;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 14px;
  transition: background-color 0.5s ease;
  &:hover {
    background: #efefef;
  }
}

.detail-dispo {
  .form-control,
  .custom-select {
    border: unset;
    border-bottom: 1px solid #d8d6de;
    border-radius: 0;
    pointer-events: none;
  }
}
</style>
